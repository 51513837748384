body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 1.6rem;
}
h2 {
  font-size: 2.1rem;
  letter-spacing: 1.4px;
  font-weight: bold;
  margin:0;
}
#nav{
  height: 100vh;
}
#nav h2, h2 {
  font-weight: 300;
  /* font-family: 'Julius Sans One', sans-serif; */
  font-family: 'Open Sans Condensed', sans-serif;
}
.bg-black {
  background-color: #1d2024
}
.no-xspace {
  display: inline-block ;
}
.no-margin {
  margin: 0px;
}
#author {
  border-radius: 100rem;
}
.image-column img.img-fluid {
  opacity: 1;
  transition: all 0.2s
}
.image-column img.img-fluid:hover {
  opacity: 0.6;
}
#menu-mobile {
  display: none;
}
.no-gutter{
  padding: 0;
}
/* #content {
  position: relative;
} */
#lightbox {
  top: 0px;
  position: fixed;
  z-index: 9999;
  height: 100%;
  /* height: 100vh */
}
.lightbox-image {
  padding-left: 0px;
  padding-right: 0px;
}
.padding-top {
  padding-top: 10rem;
}
.protrait-img{
  max-width: 28vw;
  height: auto;
}
#mobile-arrow-guide {
  display: none;
}
.nav-link {
  padding: 5px 0px;
}
#profile-img{
  background-image: url("./img/protraitshot.jpeg");
  background-size: cover;
  height: 100vh;
  width: auto;
}
/* 768 */
@media only screen and (max-width: 992px) {
  #filler, #author {
    height: 6.5rem;
    /* display: none; */
  }
  #nav {
    display: none;
  }
  #menu-mobile {
    display: inline;
  }
  .protrait-img{
    width: 80%;
    /* display: flex;
    align-content: center;
    max-width: 80%;
    height: auto; */
  }
  .arrow-guide {
    display: none;
  }
  #mobile-arrow-guide {
    display: flex;
  }
  #container {
    max-width: 1800px;
  }
  #profile-img{
    height: 40vh;
    width: 100%;
  }
  /* #gallery {
    padding: 0rem 3rem
  } */
}
